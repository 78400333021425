let screenWidth;
const siteBody = document.body;
const filters = document.querySelector(".filters");
const stickyTitle = document.querySelector(".sticky--title");
const headerUpper = document.querySelector(".header--upper");
const headerLogo = document.querySelector(".header--logo");

window.addEventListener("DOMContentLoaded", () => {
  screenWidth = window.innerWidth;
  cssRootVariable(siteBody, filters, "--filters-height");
  cssRootVariable(siteBody, stickyTitle, "--sticky-title-height");
  cssRootVariable(siteBody, headerUpper, "--header--upper");
  cssRootVariable(siteBody, headerLogo, "--header--logo");
  hashScroller(".scroll-to-hash");
  if (screenWidth >= 992) {
    hashScroller(".scroll-to-hash-extra", ".sticky--title");
  } else {
    hashScroller(".scroll-to-hash-extra", ".property__sticky--section.second");
  }
  hoverClassToggler(".plan__sliderwrap", ".plan__item", "plan__item--active");
  let js_show = document.querySelectorAll(".js-show");
  let js_show_slow = document.querySelectorAll(".js-show-slow");
  js_show.forEach((js_show_item) => {
    js_show_item.style.opacity = "1";
  });
  setTimeout(function () {
    js_show_slow.forEach((js_show_slow_item) => {
      js_show_slow_item.style.opacity = "1";
    });
  }, 2000);
});

// * CHECK IF SCREEN RESIZING HAS ENDED
var rtime;
var timeout = false;
var delta = 200;
window.addEventListener("resize", () => {
  cssRootVariable(siteBody, filters, "--filters-height");
  cssRootVariable(siteBody, stickyTitle, "--sticky-title-height");
  rtime = new Date();
  if (timeout === false) {
    timeout = true;
    setTimeout(resizeend, delta);
  }
});

let resizeend = () => {
  if (new Date() - rtime < delta) {
    setTimeout(resizeend, delta);
  } else {
    timeout = false;
    // SCREEN RESIZING ENDS HERE - APPLY CHANGES BELOW
    screenWidth = window.innerWidth;
  }
};

// Functions
let cssRootVariable = (scope, target, propertyName) => {
  if (target) {
    var value;
    if (typeof target === "number") {
      value = target;
    } else {
      value = target.offsetHeight;
    }
    scope.style.setProperty(propertyName, `${value}px`);
  }
};

// toggle class with reset to default state
let hoverClassToggler = (parent, target, toggleClass) => {
  var defaultSelectedElement = 1;
  var wrapEl = document.querySelector(parent);
  var el = document.querySelectorAll(target);

  if (wrapEl) {
    for (var i = 0; i < el.length; i++) {
      el[i].onmouseenter = function () {
        for (var j = 0; j < el.length; j++) {
          el[j].classList.remove(toggleClass);
        }
        this.classList.add(toggleClass);
      };
      el[i].onmouseleave = function () {
        this.classList.remove(toggleClass);
      };
    }
    wrapEl.onmouseleave = function () {
      el[defaultSelectedElement - 1].classList.add(toggleClass);
    };
  }
};

let hashScroller = (cl, xh) => {
  let scrollers = document.querySelectorAll(cl);
  scrollers.forEach((scroller) => {
    scroller.addEventListener("click", function (e) {
      e.preventDefault();
      headerHeight = document.querySelector("header").offsetHeight;
      $target = document.querySelector(
        this.href.substring(this.href.lastIndexOf("/") + 1),
      );
      if (xh) {
        if (screenWidth >= 992) {
          extraHeight = document.querySelector(xh).offsetHeight;
          var hh =
            $target.getBoundingClientRect().top +
            window.scrollY -
            headerHeight -
            extraHeight -
            55;
        } else {
          extraHeight = document.querySelector(xh).offsetHeight;
          var hh =
            $target.getBoundingClientRect().top +
            window.scrollY -
            headerHeight -
            extraHeight;
        }
      } else {
        var hh =
          $target.getBoundingClientRect().top + window.scrollY - headerHeight;
      }

      window.scrollTo({
        top: hh,
        behavior: "smooth",
      });
    });
  });
};

// window.addEventListener('DOMContentLoaded', () => {
//     document.addEventListener('keydown', function (e) {
//         e = e || window.event;
//         e.keyCode;
//         if (e.keyCode == 27) {
//             enquireForm.forEach(formEl => {
//                 formEl.classList.remove('active');
//             })
//             pageOverlay.classList.remove('active');
//         }
//     });
// });
