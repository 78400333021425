//js
// LIBRARY
import "./src/App";
import "./library";
// ANIMATIONS
import "./animations";
// THEME
import "./theme";
// TOOLTIP
import "./bootstrap/tooltip";
// ALERT
import "bootstrap/js/src/alert";
